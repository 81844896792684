// @flow

//import styles
import style from "./style.module.scss";

// import libs
import React, { PureComponent, Fragment } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { connectToChild } from "penpal";

// Icons
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteForever from "@material-ui/icons/DeleteForever";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import LibraryAddCheckOutlinedIcon from "@material-ui/icons/LibraryAddCheckOutlined";
import LockOpenIcon from "@material-ui/icons/LockOpen";

// import components
import AttachmentsViewerModal from "../AttachmentsViewerModal";
import CardLikeButton from "../CardLikeButton";
import ContactPersonsForm from "../ContactPersonsForm";
import CreateReportStartQC from "../CreateReportStartQC";
import FINotifications from "../FINotifications";
import FormsToolBar from "../FormsToolBar";
import InterlocutorsForm from "../InterlocutorsForm";
import ReportTitle from "../ReportTitle";
import SelectedModulesList from "../SelectedModulesList";
import SelectModules from "../SelectModules";
import VisitPeriodsForm from "../VisitPeriodsForm";

import {
    LoadingBox,
    LoadingButton,
    Redirect,
    Modal,
    SimpleExpansionPanel,
    Typography,
} from "@components/Shared";

import { REDIRECTIONS, ASSIGNMENTS, NOTIFICATIONS } from "@constants";
import {
    ModulesUtil,
    PersonUtil,
    storage,
    getAllAvailableInspectors,
    userIsMemberOfAssignment,
    isDefined,
} from "@utils";
import permissions from "../../../definitions/permissions";
import type { ApiStore, Person, Notification } from "@types";

/**
 * Props type
 */
type Props = {
    location: *,
    inspection: *,
    id: string,
    inspectionId: string,
    visitId: string,
    visitModules: *,
    modules: Array<*>,
    previewModulePdf: (
        inspectionId: string,
        moduleInstanceId?: string,
    ) => Promise<*>,
    loadingPreviewModulePdf: boolean,
    loadModulesForVisit: (
        inspectionId: string,
        visitId: string,
        inspectionPointId: string,
    ) => Promise<*>,
    updateModule: (updatedModule: *) => Promise<any>,
    addModuleLoading: boolean,
    modulePdf: ApiStore<*>,
    reportTypes: ApiStore<*>,
    loadReportTypes: (inspectionPointId: string) => Promise<*>,
    user: *,
    notify: Notification => void,
    updateInspectionProperties: (inspectionid: string, data: *) => Promise<*>,
    loadInspection: (inspectionPointId: string) => Promise<*>,
    refetchInspection: () => void,
    deleteAllModules: (inspectionId: string, visitId: string) => Promise<any>,
    uploaderValue: *,
    uploaderLoading: boolean,
    uploadImageAttachment: (
        files: Array<*>,
        inspectionId: string,
    ) => Promise<*>,
    clearAttachments: () => void,
    sendModulePropToBackend: ({}) => Promise<any>,
    previewAnonymizedReport: (inspectionId: string) => void,
    loadingAnonymizedReport: boolean,
    setApplicationState: (state: any) => Promise<any>,
    clearApplicationState: () => void,
    openUpModulesAgain: (
        inspectionId: string,
        reportState: string,
    ) => Promise<any>,
};

type State = {
    mode: string,
    connectedToForm: boolean,
    path?: string,
    formUrl: string,
    frameHeight: string,
    canGeneratePreviewReport: boolean,
    showModulesDialog: boolean,
    selectedModule: any,
    fullForm: boolean,
    isOnline: boolean,
    displayAppBar: boolean,
    showAttachmentsViewerDialog: boolean,
    showUploadImage: boolean,
    showVisitInfo: boolean,
    isValidatingAllModules: boolean,
    modulesToValidate: Array<*>,
    fiUnsavedTally: ?number,
    fiNotification: {
        open: boolean,
        message: string,
        severity?: string,
    },
    lastScrollPosition?: number,
    currentFieldIsPartOfRepeatingPanel: boolean,
    submitError: boolean,
    inbreuken: any,
    fieldWithfocus?: string,
};
const allStatuses = [
    "weggewerkt",
    "niet-weggewerkt",
    "niet-gecontroleerd",
    "geen-status",
    "niet-toetsbaar",
];
let shouldSaveDraft = false;
let currentFormField = null;
let missingFieldNames = [];
class AssignmentForms extends PureComponent<Props, State> {
    connection: {
        current: {
            promise: Promise<any>,
            destroy: () => void,
        } | null,
    };
    lastScrollPosition: { current: number | null };
    attachments = [];
    constructor(props: Props) {
        super(props);
        this.connection = React.createRef();
        this.lastScrollPosition = React.createRef();
    }
    state = {
        connectedToForm: false,
        mode: "",
        path: "",
        formUrl: "",
        frameHeight: "1000px",
        canGeneratePreviewReport: false,
        showModulesDialog: false,
        selectedModule: null,
        fullForm: false,
        isOnline: typeof navigator !== "undefined" ? navigator.onLine : false,
        displayAppBar: false,
        showAttachmentsViewerDialog: false,
        showUploadImage: false,
        showVisitInfo: false,
        isValidatingAllModules: false,
        modulesToValidate: [],
        fiUnsavedTally: null,
        fiNotification: {
            open: false,
            message: "",
        },
        lastScrollPosition: undefined,
        currentFieldIsPartOfRepeatingPanel: false,
        submitError: false,
        inbreuken: null,
        fieldWithFocus: "",
    };

    setFormWrapperContent = (msg: string) => {
        const wrapper: HTMLElement | null = document.getElementById(
            "frameWrapper",
        );
        if (wrapper) wrapper.innerHTML = msg;
    };

    connectPenPal = () => {
        const { selectedModule, formUrl, frameHeight } = this.state;
        this.setState({ connectedToForm: false });
        this.setFormWrapperContent("");
        // eslint-disable-next-line smells/no-this-assign
        const me = this;
        if (selectedModule) {
            const iframe = document.createElement("iframe");
            iframe.width = "100%";
            iframe.height = frameHeight; //$FlowFixMe
            iframe.style = "border: none;";
            iframe.frameBorder = "0";
            iframe.src = formUrl;
            iframe.id = "iframeForms";
            const wrapper: HTMLElement | null = document.getElementById(
                "frameWrapper",
            );
            wrapper && wrapper.appendChild(iframe);

            this.connection.current = connectToChild({
                iframe,
                debug: false,
                methods: {
                    init() {
                        me.setState({ connectedToForm: true });
                        if (!me.state.isOnline && me.state.selectedModule) {
                            return localStorage.getItem(
                                `${me.props.visitId}-${me.state.selectedModule.instanceId}-state`,
                            );
                        } else {
                            // we need to validate immediatly
                            if (me.state.isValidatingAllModules) {
                                me.onSubmitClick();
                            }
                            return null;
                        }
                    },
                    elementValueChanged(newText) {
                        const condition =
                            newText !== "DRAFT" && newText !== "SUBMIT";
                        if (condition) {
                            console.log("elementValueChanged to:", newText);
                            shouldSaveDraft = true;
                        }
                    },
                    elementVisibleChanged(target) {
                        // note: eg adding or removing KW items won't trigger elementValueChanged
                        // so there wasn't a draft save of the form for that before
                        // this catches that
                        // also includes check to be sure it's only the KW items and not
                        // some prefilled custom components loaded after form init
                        const containsQuali = str => str.includes("quali");
                        if (containsQuali(target)) {
                            shouldSaveDraft = true;
                        }
                    },
                    elementFocusChanged(newField, isPartOfRepeatingPanel) {
                        currentFormField = newField;
                        me.setState({
                            currentFieldIsPartOfRepeatingPanel: isPartOfRepeatingPanel,
                        });
                    },
                    elementButtonClicked(buttonName) {
                        if (
                            buttonName === "button_add_quali" ||
                            buttonName === "button_remove_quali"
                        )
                            return (shouldSaveDraft = true);
                    },
                    scrollPosition() {
                        return window?.scrollY;
                    },
                    doSubmit() {
                        me.onSubmitClick();
                        return null;
                    },
                },
            });

            this.connection.current.promise.then(() => {
                const timeout = this.selectedModuleIsFI ? 600 : 300;
                const handleRestoreScrollPosition = () => {
                    setTimeout(() => {
                        window.scrollTo(0, this.lastScrollPosition.current);
                    }, timeout);
                };
                handleRestoreScrollPosition();
            });
        }
    };

    /**
     *   Lifecycle methods
     */
    componentDidMount() {
        const { inspection, visitId, clearAttachments } = this.props;
        if (inspection && inspection.visits && inspection.visits.length) {
            this.loadModules();
        }
        if (inspection && visitId) this.prepareStateForStatus(inspection);
        this.attachListener();
        clearAttachments();
    }

    componentWillUnmount() {
        shouldSaveDraft && this.onSaveDraftClick(false);
        if (window !== undefined) {
            window.removeEventListener(
                "message",
                this.handlePostMessages.bind(this),
                false,
            );
            window.removeEventListener("online", this.setOnline);
            window.removeEventListener("offline", this.setOffline);
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        //switched to different visit
        if (this.props.visitId && prevProps.visitId !== this.props.visitId) {
            !shouldSaveDraft && this.setFormWrapperContent("");
            this.state.selectedModule &&
                shouldSaveDraft &&
                this.onSaveDraftClick(false).then(() => {
                    this.setFormWrapperContent("");
                });

            this.loadModules();
            this.setState({ selectedModule: null, showVisitInfo: false });
            this.props.clearAttachments();
        }
        // inspectors have changed
        this.handleInspectorsChange(this.props, prevProps);

        // modules have changed
        if (prevProps.modules && prevProps.modules !== this.props.modules) {
            this.checkModulesWithContent(this.props.modules);
        }
        // inspection status has changed
        if (
            this.props.inspection &&
            prevProps.inspection &&
            this.props.inspection.status !== prevProps.inspection.status
        ) {
            this.prepareStateForStatus(this.props.inspection);
        }

        // seleced module has changed
        if (prevState.selectedModule !== this.state.selectedModule) {
            this.props.clearAttachments();
            if (
                (prevState.selectedModule !== null &&
                    this.state.selectedModule !== null &&
                    prevState.selectedModule.instanceId !==
                        this.state.selectedModule.instanceId) ||
                (prevState.selectedModule === null &&
                    this.state.selectedModule !== null)
            ) {
                this.connectPenPal();
                shouldSaveDraft = false;
                currentFormField = null;
            }
        }
        if (prevState.isOnline !== this.state.isOnline) {
            if (!this.state.isOnline) {
                const { notify } = this.props;
                notify &&
                    notify({
                        severity: NOTIFICATIONS.SEVERITY.WARNING,
                        type: NOTIFICATIONS.TYPE.MODAL,
                        message:
                            "Netwerkconnectie verbroken. De validatie werd gestopt. Probeer later opnieuw, als de netwerkconnectie weer actief is.",
                        primaryActionText: "Sluiten",
                    });
                this.setState({
                    isValidatingAllModules: false,
                    modulesToValidate: [],
                });
                this.props.setApplicationState({ showLoading: false });
            }
        }

        if (
            prevState.isValidatingAllModules !==
            this.state.isValidatingAllModules
        ) {
            const { refetchInspection } = this.props;
            refetchInspection();
        }
    }
    /**
     *   End lifecycle methods
     */
    get canPerformActions(): boolean {
        const { inspection, user } = this.props;
        const isMember: boolean = userIsMemberOfAssignment(
            inspection,
            user?.sub,
        );
        const permissionDefinition: any = permissions["assignment.forms.write"];
        const hasPermissionToPerformActions: boolean =
            !!user &&
            !!permissionDefinition &&
            user.roles.some(role => permissionDefinition.roles.includes(role));

        return hasPermissionToPerformActions || isMember;
    }

    get canEditInterlocutors(): boolean {
        const { inspection, user } = this.props;
        const hasPermissionToEditInterLocutors: any =
            permissions["assignment.interlocutors.write"];
        const isMember: boolean = userIsMemberOfAssignment(
            inspection,
            user?.sub,
        );
        const userIsLeadInspector: boolean =
            inspection.leadInspector &&
            inspection.leadInspector.id === user?.sub;

        return (
            hasPermissionToEditInterLocutors || isMember || userIsLeadInspector
        );
    }

    get canEditVisitInfo(): boolean {
        const { inspection } = this.props;
        return [
            ASSIGNMENTS.STATUSSES.CREATED,
            ASSIGNMENTS.STATUSSES.PLANNED,
            ASSIGNMENTS.STATUSSES.PREPARED,
            ASSIGNMENTS.STATUSSES.DRAFT_REVIEWED,
            ASSIGNMENTS.STATUSSES.REACTION_RECEIVED,
            ASSIGNMENTS.STATUSSES.REACTION_PROCESSED,
            ASSIGNMENTS.STATUSSES.FINAL_REVIEWED,
            ASSIGNMENTS.STATUSSES.REOPENED,
            ASSIGNMENTS.STATUSSES.DRAFT_READY_TO_PUBLISH,
            ASSIGNMENTS.STATUSSES.FINAL_READY_TO_PUBLISH,
        ].includes(inspection?.status);
    }

    handleInspectorsChange = (props: Props, prevProps: Props) => {
        const { inspection, visitId } = props;
        const { modules: prevModules, inspection: prevInspection } = prevProps;
        if (
            inspection &&
            prevInspection &&
            prevModules &&
            visitId &&
            getAllAvailableInspectors(inspection).length <
                getAllAvailableInspectors(prevInspection).length
        ) {
            this.props.notify({
                severity: NOTIFICATIONS.SEVERITY.WARNING,
                message:
                    "De inspecteurs voor deze opdracht werden aangepast, modules worden opnieuw ingeladen...",
            });
            this.loadModules();
        }
    };

    handleDeleteAllModules = () => {
        const { deleteAllModules, inspectionId, visitId } = this.props;
        deleteAllModules && deleteAllModules(inspectionId, visitId);
        this.setFormWrapperContent("");
        this.setState({ selectedModule: null, displayAppBar: false });
    };

    handleDeleteSelectedOrParentModule = () => {
        this.setFormWrapperContent("");
        this.setState({ selectedModule: null, displayAppBar: false });
    };

    prepareStateForStatus = (inspection: *) => {
        const mode = this.getModeForStatus(inspection.status);
        if (mode) this.setState({ mode, formUrl: "" });
    };

    getModeForStatus = (status: string) => {
        if (!this.canPerformActions) return ASSIGNMENTS.MODES.READ_ONLY;

        const editMode = [
            ASSIGNMENTS.STATUSSES.PLANNED,
            ASSIGNMENTS.STATUSSES.CREATED,
            ASSIGNMENTS.STATUSSES.PREPARED,
            ASSIGNMENTS.STATUSSES.DRAFT_REVIEWED,
            ASSIGNMENTS.STATUSSES.DRAFT_REPORT_DELAYED,
            ASSIGNMENTS.STATUSSES.REACTION_RECEIVED,
            ASSIGNMENTS.STATUSSES.REACTION_PROCESSED,
            ASSIGNMENTS.STATUSSES.FINAL_REVIEW_REQUESTED,
            ASSIGNMENTS.STATUSSES.FINAL_REPORT_DELAYED,
            ASSIGNMENTS.STATUSSES.FINAL_REVIEWED,
            ASSIGNMENTS.STATUSSES.REOPENED,
        ];

        if (editMode.includes(status)) return ASSIGNMENTS.MODES.EDIT;

        const qualityMode = [
            ASSIGNMENTS.STATUSSES.DRAFT_REVIEW_REQUESTED,
            ASSIGNMENTS.STATUSSES.FINAL_REVIEW_REQUESTED,
        ];

        if (qualityMode.includes(status)) return ASSIGNMENTS.MODES.REVIEW;

        return ASSIGNMENTS.MODES.READ_ONLY;
    };

    handlePostMessages = (e: *) => {
        let data: any = {
            height: this.state.frameHeight,
        };
        try {
            data = JSON.parse(e.data);
        } catch (e) {
            // do nothing
        }

        if (data.hasOwnProperty("height")) {
            const minHeight = 600;
            let frameHeight =
                parseInt(data.height) < minHeight
                    ? `${minHeight}`
                    : `${data.height}`;
            frameHeight = frameHeight.concat(
                frameHeight.includes("px") ? "" : "px",
            );

            if (this.state.frameHeight !== frameHeight) {
                this.setState({
                    frameHeight,
                });
                const frm = document.getElementById("iframeForms");
                frm && frm.setAttribute("height", frameHeight);
            }
        } else if (data.hasOwnProperty("tally")) {
            this.setState({ fiUnsavedTally: data.tally });
        } else if (data.hasOwnProperty("fiNotification")) {
            this.setState({ fiNotification: data.fiNotification });
        }
        if (data.hasOwnProperty("inbreuken")) {
            if (this.state.inbreuken) {
                const inbreukenCopy = { ...this.state.inbreuken };
                Object.keys(data.inbreuken).forEach(
                    id => (inbreukenCopy[id] = data.inbreuken[id]),
                );
                this.setState({ inbreuken: inbreukenCopy });
                //check if there is one with "geen-status"
            } else {
                this.setState({ inbreuken: data.inbreuken });
            }
        }
    };

    setOnline = () => {
        this.setState({ isOnline: true });
    };
    setOffline = () => {
        this.setState({ isOnline: false });
    };

    attachListener = () => {
        if (window !== undefined) {
            window.addEventListener(
                "message",
                this.handlePostMessages.bind(this),
                false,
            );
            window.addEventListener("online", this.setOnline.bind(this), false);
            window.addEventListener(
                "offline",
                this.setOffline.bind(this),
                false,
            );
        }
    };

    checkModulesWithContent = (modules: Array<*>) => {
        const numberModulesWithContent = ModulesUtil.countModulesWithContent(
            modules,
        );
        this.setState({
            canGeneratePreviewReport:
                numberModulesWithContent >= 1 && this.canPerformActions,
        });
    };

    loadModules = () => {
        if (this.state.isOnline) {
            const {
                inspectionId,
                inspection,
                loadModulesForVisit,
                visitId,
            } = this.props;
            const visit = inspection.visits.find(visit => visit.id === visitId);
            loadModulesForVisit(
                inspectionId,
                visitId,
                visit?.inspectionPointId,
            ).then(result => {
                result && this.checkModulesWithContent(result.payload);
            });
        }
    };

    /**
     * builds form url
     */
    constructFormUrl = ({
        mode,
        path,
        inspectionId,
        moduleInstanceId,
        inspectorName,
        visitId,
        moduleId,
    }: *) => {
        //https://wwwaccforms.modular2.vlaanderen.be/content... for ACC
        //https://devforms.wvg.gluo.cloud for dev
        const formsDomain = process.env.GATSBY_FORMS_DOMAIN;
        const queryString = `?mode=${mode}&inspectionId=${inspectionId}&visitId=${visitId}&inspectorname=${inspectorName}&moduleInstanceId=${moduleInstanceId}&moduleId=${moduleId}&userId=${this.props.user.sub}`;
        return `${formsDomain ||
            "https://devforms.wvg.gluo.cloud"}/content/forms/af/${path}.html${queryString}`;
    };

    downloadModulePdfPreview = (item: *) => {
        const { previewModulePdf, inspection } = this.props;
        previewModulePdf(inspection.inspectionId, item.instanceId);
    };

    getInspectorForForm = (inspection?: *) => {
        if (!inspection) return "Ongeassigneerd";
        const getName = (person: Person) =>
            decodeURI(PersonUtil.display(person));
        const {
            given_name: firstName,
            family_name: lastName,
            sub: id,
        } = this.props.user;
        return getName({ firstName, lastName, id });
    };

    openAddModulesDialog = () => {
        const { inspection, visitId, loadReportTypes } = this.props;
        const visit =
            inspection.visits &&
            inspection.visits.find(visit => visit.id === visitId);
        loadReportTypes(visit?.inspectionPointId);
        this.setState({ showModulesDialog: true });
    };

    /**
     * Handle click
     */
    onModuleClick = (moduleInstance: *) => {
        const { mode, selectedModule, fiNotification, isOnline } = this.state;
        this.setState({
            submitError: false,
            inbreuken: null,
        });
        this.lastScrollPosition.current = 0;

        const intModuleClick = () => {
            const { inspection, visitId } = this.props;
            const inspectorName = this.getInspectorForForm(inspection);
            const formUrl = this.constructFormUrl({
                mode,
                path: moduleInstance.path,
                inspectionId: inspection.inspectionId,
                moduleInstanceId: moduleInstance.instanceId,
                inspectorName,
                visitId,
                moduleId: moduleInstance.moduleId,
            });
            const updateState = () => {
                this.setState({
                    frameHeight: "1000px",
                    selectedModule: moduleInstance,
                    formUrl,
                    showVisitInfo: false,
                    displayAppBar: true,
                    fiUnsavedTally: null,
                    fiNotification: { ...fiNotification, open: false },
                });
            };
            if (selectedModule && selectedModule.isOfflineAvailable) {
                // save draft first
                this.doGetState().then(state => {
                    this.saveFormStateToLocalStorage(state);
                    updateState();
                });
            } else {
                updateState();
            }
        };

        if (
            selectedModule &&
            selectedModule.instanceId !== moduleInstance.instanceId &&
            shouldSaveDraft &&
            isOnline
        ) {
            this.doSubmit(false).then(() => {
                intModuleClick();
            });
        } else {
            intModuleClick();
        }
    };

    /**
     * Get number of selected modules
     */
    get selectedModulesNumber(): number {
        const { modules } = this.props;
        return ModulesUtil.countModules(modules);
    }

    get shouldBeDisabledBecauseIsQC(): boolean {
        const { inspection } = this.props;
        let answer = false;

        if (
            [
                ASSIGNMENTS.STATUSSES.DRAFT_REVIEW_REQUESTED,
                ASSIGNMENTS.STATUSSES.FINAL_REVIEW_REQUESTED,
            ].includes(inspection.status)
        ) {
            answer = true;
        }

        return answer;
    }

    checkInbreukenStatus = () => {
        // $FlowFixMe
        if (this.state.inbreuken) {
            let inbreukenWithoutStatus = [];
            if (
                typeof this.state.inbreuken === "object" &&
                this.state.inbreuken !== null
            ) {
                const appIdKeys = Object.keys(this.state.inbreuken);

                appIdKeys.forEach(idKey => {
                    if (
                        // $FlowFixMe
                        this.state.inbreuken[idKey].bestaandeInbreuken
                    )
                        inbreukenWithoutStatus.push(
                            // $FlowFixMe
                            Object.hasOwn(
                                this.state.inbreuken[idKey].bestaandeInbreuken,
                                "geen-status",
                            ),
                        );
                });
                return !!inbreukenWithoutStatus.find(status => status === true);
            }
        }
    };

    validateInbreukContent = (infractions: Array<*>, missingFields: Object) => {
        infractions.forEach(inbreuk => {
            // Description check
            const longDescription = inbreuk.description?.long;
            const emptyDescription = longDescription.length < 1;

            if (!longDescription || emptyDescription) {
                missingFields.description = true;
            }

            // Explanation check
            if (
                // $FlowFixMe
                Object.hasOwn(inbreuk, "explanation") &&
                !inbreuk["explanation"]
            ) {
                missingFields.explanation = true;
            }
        });
        return missingFields;
    };

    checkMissingFields = () => {
        const missingFields = {
            status: false,
            description: false,
            explanation: false,
        };
        // $FlowFixMe
        if (
            this.state.inbreuken &&
            typeof this.state.inbreuken === "object" &&
            this.state.inbreuken !== null
        ) {
            const appIdKeys = Object.keys(this.state.inbreuken);

            appIdKeys.forEach(idKey => {
                if (
                    // $FlowFixMe
                    this.state.inbreuken[idKey].bestaandeInbreuken
                ) {
                    const bestaandeInbreuken = this.state.inbreuken[idKey]
                        .bestaandeInbreuken;
                    Object.keys(bestaandeInbreuken).forEach(objKey => {
                        if (allStatuses.some(status => status === objKey)) {
                            this.validateInbreukContent(
                                bestaandeInbreuken[objKey],
                                missingFields,
                            );
                        }
                    });

                    //Status check
                    // $FlowFixMe
                    if (Object.hasOwn(bestaandeInbreuken, "geen-status")) {
                        missingFields.status = true;
                    }
                }
                if (
                    // $FlowFixMe
                    this.state.inbreuken[idKey].nieuweInbreuken
                ) {
                    this.validateInbreukContent(
                        this.state.inbreuken[idKey].nieuweInbreuken,
                        missingFields,
                    );
                }
            });
        }
        return missingFields;
    };

    getInbreukValidationError = () => {
        const fieldTranslation = {
            status: "status",
            description: "omschrijving",
            explanation: "toelichting",
        };

        const translatedfields = missingFieldNames.map(
            fieldName => fieldTranslation[fieldName],
        );

        const errorText = `${
            translatedfields.length > 1 ? "De velden" : "het veld"
        } [${translatedfields.join(" ,")}] van één of meerdere inbreuken ${
            translatedfields.length > 1 ? "zijn" : "is"
        } niet ingevuld, gelieve deze aan te passen en opniew in te dienen`;

        return errorText;
    };

    doSubmit = (validate: boolean, invalidFields?: boolean) => {
        const { isValidatingAllModules } = this.state;
        //if false reset the scroll position (this means a module has ben clicked)
        //this function gets used by onModuleClick and handleSubmit so we need to
        //differentiate when to reset and when to hold the position
        const resetScrollPosition = !validate;

        this.lastScrollPosition.current =
            resetScrollPosition && window ? 0 : window.scrollY;
        if (this.connection.current) {
            return this.connection.current.promise
                .then(child => {
                    return child
                        .submit(validate)
                        .then(result => {
                            const { notify } = this.props;
                            const message =
                                validate && !invalidFields
                                    ? result
                                        ? "Gevalideerde module werd bewaard"
                                        : "Verplichte velden zijn niet allemaal ingevuld."
                                    : invalidFields
                                    ? this.getInbreukValidationError()
                                    : "Een niet-gevalideerde, tijdelijke versie van de module werd bewaard.";
                            notify({
                                severity:
                                    !invalidFields && result
                                        ? NOTIFICATIONS.SEVERITY.SUCCESS
                                        : NOTIFICATIONS.SEVERITY.ERROR,
                                message,
                            });

                            this.setState({ submitError: false });

                            result &&
                                !isValidatingAllModules &&
                                setTimeout(() => this.loadModules(), 1000);
                            return result;
                        })
                        .catch((err: *) => {
                            const { notify } = this.props;
                            const message =
                                "Er is een fout opgetreden bij het indienen, gegevens werden niet bewaard";
                            notify({
                                severity: NOTIFICATIONS.SEVERITY.ERROR,
                                message,
                                autoHide: false,
                            });
                            this.setState({ submitError: true });
                            console.log("Failed attempt to submit module", err);
                        });
                })
                .catch(() => {
                    setTimeout(() => this.loadModules(), 1000);
                });
        } else {
            return Promise.reject();
        }
    };

    onSaveDraftClick = (invalidFields?: boolean = false) => {
        const intSubmit = () => {
            return this.doSubmit(false, invalidFields);
        };
        const {
            selectedModule,
            isOnline,
            isValidatingAllModules,
            submitError,
        } = this.state;
        const { notify, inspectionId, loadInspection } = this.props;

        //if (shouldSaveDraft) {
        shouldSaveDraft = false;
        if (selectedModule && selectedModule.isOfflineAvailable) {
            // store latest state
            return this.doGetState().then(state => {
                this.saveFormStateToLocalStorage(state);
                if (isOnline) return intSubmit();
                return notify({
                    message:
                        "Een niet-gevalideerde, tijdelijke versie van de module werd offline bewaard - enkel op dit toestel. Vergeet deze niet in te dienen eens er terug verbinding is.",
                    severity: NOTIFICATIONS.SEVERITY.WARNING,
                });
            });
        } else {
            let updatedSelectedModule: any = { ...this.state.selectedModule };
            if (!submitError) updatedSelectedModule.submissionState = "DRAFT";

            if (!isValidatingAllModules) {
                this.setState(
                    {
                        selectedModule: updatedSelectedModule,
                    },
                    () => {
                        setTimeout(() => {
                            loadInspection(inspectionId);
                        }, 1000);
                    },
                );
            }

            return intSubmit();
        }

        /*} else {
            return Promise.resolve();
        }*/
    };

    clearCacheForForm = (form: *) => {
        if (this.connection.current) {
            return this.connection.current.promise
                .then(child => {
                    return child
                        .clearCacheForForm(form)
                        .then(state => {
                            return state;
                        })
                        .catch(error =>
                            console.log(
                                "an error occured in clearCacheForForm",
                                error,
                            ),
                        );
                })
                .catch(error =>
                    console.log(
                        " error occured in clearCacheForForm() ",
                        error,
                    ),
                );
        } else {
            return Promise.reject();
        }
    };

    setSubmissionStatus = (status: string) => {
        if (this.connection.current) {
            return this.connection.current.promise
                .then(child => {
                    return child
                        .setSubmissionStatus(status)
                        .then(state => {
                            return state;
                        })
                        .catch(error =>
                            console.log(
                                "an error occured in setSubmissionStatus",
                                error,
                            ),
                        );
                })
                .catch(error =>
                    console.log(
                        " error occured in setSubmissionStatus() ",
                        error,
                    ),
                );
        } else {
            return Promise.reject();
        }
    };

    removeFormFromLocalStorage = (form: *) => {
        if (!form) return;

        const { visitId, updateModule } = this.props;
        const { selectedModule } = this.state;

        localStorage.removeItem(`${visitId}-${form.instanceId}-state`);
        localStorage.removeItem(`${visitId}-${form.instanceId}-form`);

        const updatedModule = {
            ...selectedModule,
            isOfflineAvailable: false,
        };
        updateModule(updatedModule);
        this.setState({ selectedModule: updatedModule });
    };

    getInvalidFieldNames = () => {
        const missingFields = this.checkMissingFields();
        const invalidFieldNames = [];

        Object.keys(missingFields).forEach(fieldName => {
            if (missingFields && missingFields[fieldName])
                invalidFieldNames.push(fieldName);
        });
        return invalidFieldNames;
    };

    onSubmitClick = () => {
        const {
            selectedModule,
            isValidatingAllModules,
            modulesToValidate,
            submitError,
        } = this.state;
        const { inspectionId, loadInspection } = this.props;
        // $FlowFixMe
        this.getFocusElement().then((result): any => {
            // $FlowFixMe
            this.setState({ fieldWithFocus: result ? result : null });
        });
        const invalidFieldnames = this.getInvalidFieldNames();
        missingFieldNames = invalidFieldnames;

        if (missingFieldNames.length > 0) {
            this.onSaveDraftClick(true);
            return;
        }

        this.doSubmit(true).then(result => {
            shouldSaveDraft = result ? false : true;
            if (result) {
                this.clearCacheForForm(selectedModule).then(() => {
                    this.removeFormFromLocalStorage(selectedModule);
                });
                if (!isValidatingAllModules) {
                    this.connectPenPal();
                    setTimeout(() => this.loadModules(), 1000);
                }
            } else {
                this.setSubmissionStatus("DRAFT").then(() => {
                    console.log("STATUS SUCCESFULLY CHANGED");
                });
            }
            if (modulesToValidate.length - 1 > 0) {
                const modules = [...modulesToValidate];
                modules.shift(); //get rid of the one you just saved.
                this.setState({ modulesToValidate: modules });
                this.onModuleClick(modules[0]);
            } else {
                let updatedSelectedModule: any = {
                    ...this.state.selectedModule,
                };

                if (!submitError)
                    updatedSelectedModule.submissionState = "SUBMIT";

                this.setState(
                    {
                        isValidatingAllModules: false,
                        modulesToValidate: [],
                        selectedModule: updatedSelectedModule,
                    },
                    () => {
                        setTimeout(() => this.loadModules(), 1000);
                    },
                );
                this.props.setApplicationState({ showLoading: false });
            }
            if (!isValidatingAllModules) {
                setTimeout(() => {
                    loadInspection(inspectionId);
                }, 1000);
            }
            if (
                this.state.fieldWithFocus &&
                this.state.fieldWithFocus !== "" &&
                this.state.fieldWithFocus !== null
            ) {
                // $FlowFixMe
                this.setFocusElement(this.state.fieldWithFocus);
                // $FlowFixMe
                setTimeout(() => {
                    //find element in focus/active
                    let elementInFocus = document.activeElement;

                    //get element
                    // $FlowFixMe
                    let element = elementInFocus.getBoundingClientRect();

                    // scroll into focus
                    window.scrollTo({
                        top: element.top + window.scrollY,
                        behavior: "smooth",
                    });
                }, 1000);
            }
        });
    };

    doGetState = () => {
        if (this.connection.current) {
            return this.connection.current.promise
                .then(child => {
                    return child
                        .getState()
                        .then(state => {
                            return state;
                        })
                        .catch(error =>
                            console.log("an error occured in getState", error),
                        );
                })
                .catch(error =>
                    console.log(" error occured in doGetState() ", error),
                );
        } else {
            return Promise.reject();
        }
    };

    saveFormStateToLocalStorage = (formState: *) => {
        const { selectedModule } = this.state;
        if (formState && selectedModule) {
            const { visitId } = this.props;
            localStorage.setItem(
                `${visitId}-${selectedModule.instanceId}-state`,
                JSON.stringify(formState),
            );
        }
    };

    saveFormAsDownloaded = (selectedModule: *) => {
        if (selectedModule) {
            const {
                visitId,
                inspectionId,
                updateModule,
                inspection,
            } = this.props;
            localStorage.setItem(
                `${visitId}-${selectedModule.instanceId}-form`,
                JSON.stringify(selectedModule),
            );
            selectedModule.isOfflineAvailable = true;
            updateModule({
                ...selectedModule,
                isOfflineAvailable: true,
            });
            const visit = inspection.visits.find(visit => visit.id === visitId);
            this.props.sendModulePropToBackend({
                inspectionId,
                visitId,
                inspectionPointId: visit?.inspectionPointId,
                moduleInstanceId: selectedModule.instanceId,
                propToUpdate: "OFFLINE",
                valueToUpdate: true,
            });
        }
    };

    makeOfflineAvailable = () => {
        this.doGetState()
            .then(formState => {
                console.log("makeOfflineAvailable => formSate =", formState);
                this.saveFormStateToLocalStorage(formState);
                this.saveFormAsDownloaded(this.state.selectedModule);
            })
            .catch(error => console.log("Offline error occured : ", error));
    };

    toggleHideInReport = (hideInReport: *) => {
        const selectedModule = this.state.selectedModule;
        if (selectedModule) {
            const {
                visitId,
                inspectionId,
                updateModule,
                inspection,
            } = this.props;
            updateModule({
                ...selectedModule,
                hideInReport: hideInReport,
            });
            const visit = inspection.visits.find(visit => visit.id === visitId);
            this.props.sendModulePropToBackend({
                inspectionId,
                visitId,
                inspectionPointId: visit?.inspectionPointId,
                moduleInstanceId: selectedModule.instanceId,
                propToUpdate: "HIDE_IN_REPORT",
                valueToUpdate: hideInReport,
            });
            selectedModule.hideInReport = hideInReport;
        }
    };

    onRestoreStateClick = () => {
        const { selectedModule } = this.state;
        const { visitId } = this.props;
        if (selectedModule) {
            const state = localStorage.getItem(
                `${visitId}-${selectedModule.instanceId}-state`,
            );
            if (this.connection.current) {
                return this.connection.current.promise
                    .then(child => {
                        return child
                            .restoreState(state)
                            .then(result => {
                                return result;
                            })
                            .catch(error =>
                                console.log(
                                    "an error occured in restoreState",
                                    error,
                                ),
                            );
                    })
                    .catch(error =>
                        console.log(
                            " error occured in onRestoreStateClick() ",
                            error,
                        ),
                    );
            }
        }
    };

    handleShowVisitInfo = () => {
        const { selectedModule } = this.state;

        if (selectedModule && shouldSaveDraft) {
            this.onSaveDraftClick(false).then(() => {
                this.setState({
                    selectedModule: null,
                    displayAppBar: false,
                    showVisitInfo: !this.state.showVisitInfo,
                    submitError: false,
                    inbreuken: null,
                });
                this.setFormWrapperContent("");
            });
        } else {
            this.setState({
                selectedModule: null,
                displayAppBar: false,
                showVisitInfo: !this.state.showVisitInfo,
                submitError: false,
                inbreuken: null,
            });
            this.setFormWrapperContent("");
        }
    };

    get OfflineContacts() {
        const { inspectionId } = this.props;
        if (storage.get(`offline-contacts-${inspectionId}`)) {
            return <Chip color="primary" label="offline Ontvangers" />;
        } else {
            return null;
        }
    }

    triggerAttachmentDialog = (showUploadComponent: boolean = false) => {
        this.setState({
            showAttachmentsViewerDialog: true,
            showUploadImage: showUploadComponent,
        });
    };

    sendAttachmentInfoToForm = (attachments: Array<*>) => {
        this.setState({
            showAttachmentsViewerDialog: false,
        });
        if (this.connection.current) {
            return this.connection.current.promise.then(child => {
                return child
                    .appendTextToField(attachments, currentFormField)
                    .catch(() => {
                        console.log(
                            "Failed attempt to add to textfield module",
                        );
                    });
            });
        } else {
            return Promise.reject();
        }
    };

    getFocusElement = () => {
        if (this.connection.current) {
            return this.connection.current.promise.then(child => {
                return child.getFocusElement().catch(() => {
                    console.log("Failed attempt to get focusElement");
                });
            });
        } else {
            return Promise.reject();
        }
    };

    setFocusElement = (somExpr: string) => {
        if (this.connection.current) {
            let som = somExpr ? somExpr : "";
            return this.connection.current.promise.then(child => {
                return child.setFocusElement(som).catch(() => {
                    console.log("Failed attempt to set focusElement");
                });
            });
        } else {
            return Promise.reject();
        }
    };

    get visit() {
        const { inspection, visitId } = this.props;
        const visit =
            inspection && inspection.visits.find(visit => visit.id === visitId);
        return visit;
    }

    get canEditReportTitle() {
        const { inspection } = this.props;
        const allowedStatuses = [
            ASSIGNMENTS.STATUSSES.PLANNED,
            ASSIGNMENTS.STATUSSES.CREATED,
            ASSIGNMENTS.STATUSSES.PREPARED,
            ASSIGNMENTS.STATUSSES.DRAFT_REVIEWED,
            ASSIGNMENTS.STATUSSES.FINAL_REVIEWED,
            ASSIGNMENTS.STATUSSES.FINAL_REVIEW_REQUESTED,
            ASSIGNMENTS.STATUSSES.DRAFT_REVIEW_REQUESTED,
            ASSIGNMENTS.STATUSSES.DRAFT_REPORT_PUBLISHED,
            ASSIGNMENTS.STATUSSES.REACTION_RECEIVED,
            ASSIGNMENTS.STATUSSES.REACTION_PROCESSED,
        ];
        return (
            this.canPerformActions &&
            allowedStatuses.includes(inspection.status)
        );
    }

    handleClearOfflineForm = () => {
        const { notify } = this.props;
        notify &&
            notify({
                severity: NOTIFICATIONS.SEVERITY.WARNING,
                type: NOTIFICATIONS.TYPE.MODAL,
                message:
                    "Bent u zeker dat u de offline versie van het formulier wilt verwijderen?",
                primaryActionText: "Ja, verwijderen",
                primaryAction: () => {
                    const { selectedModule } = this.state;
                    this.removeFormFromLocalStorage(selectedModule);
                },
                secondaryActionText: "Annuleren",
            });
    };

    /**
     * Preview Anonymized Report
     */
    onAnonymizedReportPreview = () => {
        const { previewAnonymizedReport, inspectionId } = this.props;
        previewAnonymizedReport(inspectionId);
    };

    // validate all modules

    validateAllModules = () => {
        if (this.state.isValidatingAllModules) {
            if (this.state.modulesToValidate.length > 0) {
                this.props.setApplicationState({ showLoading: true });
                this.onModuleClick(this.state.modulesToValidate[0]);
            }
        }
    };

    handleValidateAllModulesClick = () => {
        const { selectedModule } = this.state;
        const { modules } = this.props;
        const instances = [];

        modules.map(topLevel => {
            topLevel.moduleInstances.map(instance => {
                if (instance.submoduleInstances) {
                    instance.submoduleInstances.map(subinstance => {
                        instances.push({ ...subinstance });
                    });
                } else {
                    instances.push({ ...instance });
                }
            });
        });

        const hasFI = instances.some(instance =>
            instance.path.includes("fi-form"),
        );

        const init = () =>
            this.setState(
                {
                    isValidatingAllModules: true,
                    modulesToValidate: instances,
                    selectedModule: null,
                },
                () => this.validateAllModules(),
            );

        const showWarningModal = () => {
            const { notify } = this.props;
            notify &&
                notify({
                    severity: NOTIFICATIONS.SEVERITY.WARNING,
                    type: NOTIFICATIONS.TYPE.MODAL,
                    message: (
                        <Fragment>
                            {hasFI && (
                                <Typography type="subtitle1" component="span">
                                    Opgepast:{" "}
                                    <Typography
                                        type="body1Inline"
                                        component="span"
                                    >
                                        Deze knop niet gebruiken bij formulier
                                        FI. Bij dit formulier mag enkel manueel
                                        gevalideerd worden per blok. De gegevens
                                        van alle blokken in dit formulier die
                                        niet manueel gevalideerd en ingediend
                                        werden, zullen verloren gaan.
                                    </Typography>
                                </Typography>
                            )}
                            <Typography
                                type="body1"
                                component="span"
                                className={style.spacer}
                            >
                                Bij het valideren van alle modules gaat de
                                applicatie in wachtstand. Van zodra alle modules
                                gevalideerd en ingediend zijn kan u
                                verderwerken.
                            </Typography>
                        </Fragment>
                    ),
                    primaryActionText: "Ja, valideer alle modules",
                    primaryAction: () => {
                        init();
                    },
                    secondaryActionText: "Annuleren",
                });
        };

        if (selectedModule && !this.selectedModuleIsFI) {
            this.onSaveDraftClick(false)
                .then(showWarningModal)
                .catch(console.log);
        } else showWarningModal();
    };

    reEnableModules = () => {
        this.setState({ selectedModule: null });
        this.setFormWrapperContent("");
        const { openUpModulesAgain, inspectionId, inspection } = this.props;
        const type =
            ASSIGNMENTS.STATUSSES.DRAFT_REPORT_CREATED === inspection.status
                ? "DRAFT"
                : "FINAL";
        openUpModulesAgain && openUpModulesAgain(inspectionId, type);
    };

    get isReadOnly(): boolean {
        const { inspection } = this.props;

        return (
            this.getModeForStatus(inspection.status) ===
            ASSIGNMENTS.MODES.READ_ONLY
        );
    }

    get selectedModuleIsFI(): boolean {
        const { selectedModule } = this.state;
        return !!selectedModule && selectedModule.path.includes("fi-form");
    }

    /**
     * Render
     */
    render() {
        const {
            id,
            inspection,
            previewModulePdf,
            loadingPreviewModulePdf,
            addModuleLoading,
            modules,
            visitModules,
            reportTypes,
            visitId,
            user,
            inspectionId,
            loadingAnonymizedReport,
        } = this.props;
        const {
            selectedModule,
            canGeneratePreviewReport,
            showModulesDialog,
            fullForm,
            connectedToForm,
            isOnline,
            displayAppBar,
            showAttachmentsViewerDialog,
            fiUnsavedTally,
            fiNotification,
            currentFieldIsPartOfRepeatingPanel,
            showVisitInfo,
        } = this.state;

        return (
            <Fragment>
                {inspection ? (
                    <Fragment>
                        <Modal
                            id={`${id}-mdlAddModules`}
                            title="Modules toevoegen"
                            isOpen={showModulesDialog}
                            primaryButton={{
                                action: () =>
                                    this.setState({
                                        showModulesDialog: false,
                                    }),
                                text: "Ok",
                            }}
                        >
                            <Box className={style.modulesModalContent}>
                                <Box className={style.modulesModalList}>
                                    <SelectModules
                                        id={`${id}-selectModules`}
                                        selectedModulesNumber={
                                            this.selectedModulesNumber
                                        }
                                        selectedModulesLoading={
                                            visitModules.loading
                                        }
                                        modules={modules}
                                        assignment={inspection}
                                        deleteAllModules={
                                            this.handleDeleteAllModules
                                        }
                                        reportTypes={reportTypes.data}
                                        reportTypesLoading={reportTypes.loading}
                                        currentUser={user}
                                        visitId={visitId}
                                        selectedInstanceId={
                                            this.state.selectedModule
                                                ?.instanceId
                                        }
                                        selectedParentInstanceId={
                                            this.state.selectedModule
                                                ?.parentInstanceId
                                        }
                                        onDeleteSelectedOrParent={
                                            this
                                                .handleDeleteSelectedOrParentModule
                                        }
                                    />
                                </Box>
                            </Box>
                        </Modal>

                        <AttachmentsViewerModal
                            id={`${id}-mdlAttachementsViewer`}
                            isOpen={showAttachmentsViewerDialog}
                            onSubmit={this.sendAttachmentInfoToForm}
                            onCancel={() =>
                                this.setState({
                                    showAttachmentsViewerDialog: false,
                                })
                            }
                            showUploadImage={this.state.showUploadImage}
                            inspectionId={inspectionId}
                        />

                        <Grid
                            container
                            spacing={5}
                            className={fullForm ? style.relative : undefined}
                        >
                            <Grid item xs={6} md={4} xl={3}>
                                <CardLikeButton
                                    id={`${id}-button-visitinfo`}
                                    onClick={this.handleShowVisitInfo}
                                    isActive={showVisitInfo}
                                    firstItem
                                >
                                    Bezoekgegevens
                                </CardLikeButton>
                                <Box className={style.stickyBlock}>
                                    <SimpleExpansionPanel
                                        id={`${id}-Modules`}
                                        title={`Modules (${this.selectedModulesNumber})`}
                                        compact
                                        defaultExpanded={true}
                                        maxHeight="45em"
                                        hideDivider
                                    >
                                        <Box
                                            className={`${style.modulesList} ${
                                                !this.canPerformActions ||
                                                !isOnline
                                                    ? style.noActions
                                                    : ""
                                            }`}
                                        >
                                            {visitModules.loading && (
                                                <LoadingBox p={3} size={24} />
                                            )}
                                            {!visitModules.loading && modules && (
                                                <Box
                                                    className={
                                                        modules.length
                                                            ? style.filled
                                                            : style.empty
                                                    }
                                                >
                                                    <SelectedModulesList
                                                        id={`${id}-lstModules`}
                                                        modules={modules}
                                                        visitId={visitId}
                                                        currentUser={user}
                                                        assignment={inspection}
                                                        modulesLoading={
                                                            addModuleLoading
                                                        }
                                                        onViewModule={
                                                            this
                                                                .downloadModulePdfPreview
                                                        }
                                                        listComponent="nav"
                                                        onModuleClick={(
                                                            moduleInstance: *,
                                                        ) => {
                                                            setTimeout(
                                                                () =>
                                                                    this.onModuleClick(
                                                                        moduleInstance,
                                                                    ),
                                                                250,
                                                            );
                                                        }}
                                                        shouldHideActionMenu={
                                                            this
                                                                .shouldBeDisabledBecauseIsQC ||
                                                            this.isReadOnly ||
                                                            !this
                                                                .canPerformActions
                                                        }
                                                        selectedInstanceId={
                                                            this.state
                                                                .selectedModule
                                                                ?.instanceId
                                                        }
                                                        selectedParentInstanceId={
                                                            this.state
                                                                .selectedModule
                                                                ?.parentInstanceId
                                                        }
                                                        onDeleteSelectedOrParent={
                                                            this
                                                                .handleDeleteSelectedOrParentModule
                                                        }
                                                        compact
                                                    />
                                                </Box>
                                            )}
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                alignItems="flex-start"
                                            >
                                                {isOnline &&
                                                    this.canPerformActions && (
                                                        <Button
                                                            startIcon={
                                                                <AddIcon fontSize="inherit" />
                                                            }
                                                            id={`${id}-btnAddModules`}
                                                            color="primary"
                                                            onClick={() =>
                                                                this.openAddModulesDialog()
                                                            }
                                                            disabled={
                                                                this
                                                                    .shouldBeDisabledBecauseIsQC ||
                                                                this.isReadOnly
                                                            }
                                                            aria-label="modules toevoegen"
                                                            className={
                                                                style.modulesListButton
                                                            }
                                                        >
                                                            Modules toevoegen
                                                        </Button>
                                                    )}
                                                {modules.length > 0 &&
                                                    isOnline &&
                                                    this.canPerformActions && (
                                                        <Button
                                                            startIcon={
                                                                <DeleteForever fontSize="inherit" />
                                                            }
                                                            id={`${id}-btnRemoveAllModules`}
                                                            color="primary"
                                                            onClick={() =>
                                                                this.handleDeleteAllModules()
                                                            }
                                                            disabled={
                                                                this
                                                                    .shouldBeDisabledBecauseIsQC ||
                                                                this.isReadOnly
                                                            }
                                                            aria-label="Alle modules verwijderen"
                                                            className={
                                                                style.modulesListButton
                                                            }
                                                        >
                                                            Alle modules
                                                            verwijderen
                                                        </Button>
                                                    )}
                                                {modules.length > 0 &&
                                                    isOnline &&
                                                    this.canPerformActions && (
                                                        <Button
                                                            startIcon={
                                                                <LibraryAddCheckOutlinedIcon fontSize="inherit" />
                                                            }
                                                            id={`${id}-btnhandleValidateAllModulesClick`}
                                                            color="primary"
                                                            onClick={() =>
                                                                this.handleValidateAllModulesClick()
                                                            }
                                                            disabled={
                                                                this
                                                                    .shouldBeDisabledBecauseIsQC ||
                                                                this.state
                                                                    .isValidatingAllModules ||
                                                                this.isReadOnly
                                                            }
                                                            aria-label="Alle modules valideren"
                                                            className={
                                                                style.modulesListButton
                                                            }
                                                        >
                                                            Alle modules
                                                            valideren
                                                        </Button>
                                                    )}
                                                {modules.length > 0 &&
                                                    isOnline &&
                                                    this.canPerformActions && (
                                                        <Button
                                                            startIcon={
                                                                <LockOpenIcon fontSize="inherit" />
                                                            }
                                                            id={`${id}-btnHandleReleaseAllModules`}
                                                            color="primary"
                                                            onClick={() =>
                                                                this.reEnableModules()
                                                            }
                                                            disabled={
                                                                ![
                                                                    ASSIGNMENTS
                                                                        .STATUSSES
                                                                        .DRAFT_REPORT_CREATED,
                                                                    ASSIGNMENTS
                                                                        .STATUSSES
                                                                        .FINAL_REPORT_CREATED,
                                                                    ASSIGNMENTS
                                                                        .STATUSSES
                                                                        .DRAFT_READY_TO_PUBLISH,
                                                                    ASSIGNMENTS
                                                                        .STATUSSES
                                                                        .FINAL_READY_TO_PUBLISH,
                                                                    ASSIGNMENTS
                                                                        .STATUSSES
                                                                        .REOPENED,
                                                                ].includes(
                                                                    this.props
                                                                        .inspection
                                                                        .status,
                                                                )
                                                            }
                                                            className={
                                                                style.modulesListButton
                                                            }
                                                        >
                                                            Alle modules weer
                                                            editeerbaar maken
                                                        </Button>
                                                    )}
                                            </Box>
                                        </Box>
                                    </SimpleExpansionPanel>

                                    {canGeneratePreviewReport && (
                                        <LoadingButton
                                            icon={
                                                <VisibilityIcon fontSize="inherit" />
                                            }
                                            id={`${id}-btnViewReport`}
                                            onClick={() =>
                                                previewModulePdf(
                                                    inspection.inspectionId,
                                                )
                                            }
                                            ariaLabel="Verslag bekijken"
                                            fullWidth
                                            size="large"
                                            loading={loadingPreviewModulePdf}
                                        >
                                            verslag bekijken
                                        </LoadingButton>
                                    )}
                                    {canGeneratePreviewReport && (
                                        <LoadingButton
                                            icon={
                                                <SystemUpdateAltIcon fontSize="inherit" />
                                            }
                                            className={
                                                style.btnAnonymousViewReport
                                            }
                                            id={`${id}-btnAnonymousViewReport`}
                                            onClick={
                                                this.onAnonymizedReportPreview
                                            }
                                            ariaLabel="Geanonimiseerd verslag bekijken"
                                            fullWidth
                                            size="large"
                                            loading={loadingAnonymizedReport}
                                        >
                                            Geanonimiseerd verslag bekijken
                                        </LoadingButton>
                                    )}

                                    {this.canPerformActions &&
                                        !visitModules.loading &&
                                        modules && (
                                            <Fragment>
                                                <Divider />

                                                <CreateReportStartQC
                                                    id={`${id}-create-report-start-qc-section`}
                                                    orientation="vertical"
                                                />
                                            </Fragment>
                                        )}
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                md={8}
                                xl={9}
                                className={
                                    fullForm ? style.fullForm : undefined
                                }
                            >
                                <ReportTitle
                                    id={`${id}-report-title`}
                                    inspection={inspection}
                                    canEdit={this.canEditReportTitle}
                                />
                                {showVisitInfo && (
                                    <Fragment>
                                        <SimpleExpansionPanel
                                            title={
                                                this.visit?.physicalVisit
                                                    ? `Bezoekmomenten (${this
                                                          .visit?.visitPeriods
                                                          ?.length || 0})`
                                                    : "Geen bezoekmomenten"
                                            }
                                            id={`${id}-panel-bezoekmomenten`}
                                        >
                                            <VisitPeriodsForm
                                                id={`${id}-visit-periods-form`}
                                                inspectionId={inspectionId}
                                                visitId={visitId}
                                                visitPeriods={
                                                    this.visit?.visitPeriods
                                                }
                                                physicalVisit={
                                                    !!this.visit?.physicalVisit
                                                }
                                                canPerformActions={
                                                    this.canPerformActions &&
                                                    this.canEditVisitInfo
                                                }
                                            />
                                        </SimpleExpansionPanel>

                                        <SimpleExpansionPanel
                                            title="Gesprekspartners"
                                            id={`${id}-panel-gesprekspartners`}
                                        >
                                            <InterlocutorsForm
                                                inspectionId={inspectionId}
                                                visitId={visitId}
                                                canPerformActions={
                                                    this.canPerformActions &&
                                                    this.canEditVisitInfo &&
                                                    this.canEditInterlocutors
                                                }
                                            />
                                        </SimpleExpansionPanel>

                                        <SimpleExpansionPanel
                                            title={
                                                isDefined(this.OfflineContacts) //$FlowFixMe
                                                    ? `Ontvangers verslagen (${this.OfflineContacts})`
                                                    : "Ontvangers verslagen"
                                            }
                                            id={`${id}-panel-ontvangers-verslagen`}
                                        >
                                            <ContactPersonsForm
                                                id={`${id}-contact-persons`}
                                                inspectionId={inspectionId}
                                                hideBackButton
                                            />
                                        </SimpleExpansionPanel>
                                    </Fragment>
                                )}
                                {displayAppBar && (
                                    <FormsToolBar
                                        className={
                                            fullForm ? style.stickyToolbar : ""
                                        }
                                        id={`${id}-form-toolbar`}
                                        isReadOnly={
                                            this.isReadOnly ||
                                            !this.canPerformActions
                                        }
                                        selectedModule={selectedModule}
                                        extraSpacing={this.canEditReportTitle}
                                        isOnline={isOnline}
                                        fullForm={fullForm}
                                        onSaveDraft={
                                            this.selectedModuleIsFI
                                                ? undefined
                                                : () =>
                                                      this.onSaveDraftClick(
                                                          false,
                                                      )
                                        }
                                        onSubmit={this.onSubmitClick}
                                        cannotTakeOffline={
                                            !this.canPerformActions ||
                                            (connectedToForm &&
                                                !!selectedModule &&
                                                selectedModule.isOfflineAvailable)
                                        }
                                        toggleFullScreen={() =>
                                            this.setState(prevState => ({
                                                fullForm: !prevState.fullForm,
                                            }))
                                        }
                                        makeOfflineAvailable={
                                            this.makeOfflineAvailable
                                        }
                                        toggleHideInReport={() => {
                                            selectedModule &&
                                                this.toggleHideInReport(
                                                    !selectedModule.hideInReport,
                                                );
                                        }}
                                        triggerAttachmentDialog={
                                            this.selectedModuleIsFI ||
                                            currentFieldIsPartOfRepeatingPanel ||
                                            !this.canPerformActions
                                                ? undefined
                                                : this.triggerAttachmentDialog
                                        }
                                        clearOfflineForm={
                                            this.canPerformActions
                                                ? this.handleClearOfflineForm
                                                : undefined
                                        }
                                        restoreState={this.onRestoreStateClick}
                                    />
                                )}
                                {displayAppBar && (
                                    <FINotifications
                                        id={`${id}-FI-notifications`}
                                        fiNotification={fiNotification}
                                        onCloseNotification={() =>
                                            this.setState({
                                                fiNotification: {
                                                    ...fiNotification,
                                                    open: false,
                                                },
                                            })
                                        }
                                        fiUnsavedTally={fiUnsavedTally}
                                    />
                                )}
                                <div id="frameWrapper"></div>
                            </Grid>
                        </Grid>
                    </Fragment>
                ) : (
                    <Redirect data={REDIRECTIONS.ASSIGNMENTS_FORM} />
                )}
            </Fragment>
        );
    }
}

export default AssignmentForms;
